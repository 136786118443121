:root {
  --black: #000;
  --red: #cd3131;
  --green: #0ebc79;
  --yellow: #e5e50e;
  --blue: #2572c8;
  --magenta: #bc3fbc;
  --cyan: #0fa8cd;
  --white: #e5e5e5;
}
body {
  background-color: #1e1e1e;
  color: var(--white);
  font-family: "Courier New", Courier, monospace;
}

input {
  border: none;
  color: var(--white);
  background-color: #1e1e1e;
  font-family: "Courier New", Courier, monospace;
  padding: 0;
  font-size: 1rem;
  caret-color: transparent;
}

input:focus {
  outline: none;
}

a {
  color: var(--cyan);
  font-weight: 500;
  text-decoration: none;
}

a:hover {
  font-weight: 600;
}

#term-wrap {
  margin: 1.5rem;
  margin-top: 0.75rem;
}

@keyframes cursor-blink {
  0% {
    opacity: 0;
  }
}
.cursor {
  font-size: 1rem;
  width: 1ch;
  height: 1rem;
  color: var(--white);
  /* background-color: var(--white); */
  display: inline-block;
  animation: cursor-blink 1s steps(2) infinite;
  position: relative;
}

/* quick color classes so i can be lazy */
.black {
  color: var(--black);
}
.red {
  color: var(--red);
}
.green {
  color: var(--green);
}
.yellow {
  color: var(--yellow);
}
.blue {
  color: var(--blue);
}
.magenta {
  color: var(--magenta);
}
.cyan {
  color: var(--cyan);
}
.white {
  color: var(--white);
}

.bold {
  font-weight: 900;
}

.result-line {
  margin: 0;
  padding: 0;
  min-height: 1rem;
  max-width: 100ch;
}

.commandBtn {
  color: yellow;
  cursor: pointer;
}

.tabbed {
  position: relative;
  left: 2ch;
}
